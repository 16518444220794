import React from "react"
import Layout from "../components/layout"
import { css } from "emotion"
import { Container, Row, Col, Button } from "reactstrap"
import BrowserThumb from "../components/browserThumb"
import emailImg from "../assets/email.png"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      message: "",
      showSuccess: false,
    }
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state }),
    })
      .then(() => this.setState({ showSuccess: true }))
      .catch(error => alert(error))

    e.preventDefault()
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  render() {
    const { name, email, message } = this.state
    return (
      <Layout headerType="contact" headerText="Contact">
        <Container
          className={css`
            margin-top: 30px;
          `}
        >
          {!this.state.showSuccess ? (
            <Row>
              <Col className={"col-12 col-lg-5 offset-lg-1"}>
                <h1>Howzit</h1>
                <p>
                  Send me an email to let me know what you think of my new
                  website or just say what's up.{" "}
                </p>
                <p>&nbsp;</p>
                <BrowserThumb background={emailImg} />
              </Col>
              <Col className={"col-12 col-lg-5"}>
                <form
                  onSubmit={this.handleSubmit}
                  className={css`
                    text-align: center;
                  `}
                >
                  <p className="invisible">
                    <label for="bot-field">
                      Don’t fill this out if you're human:{" "}
                      <input name="bot-field" />
                    </label>
                  </p>
                  <p className="text-left">
                    <label for="name">Your Name: </label>
                    <input
                      type="text"
                      name="name"
                      value={name}
                      onChange={this.handleChange}
                      className={css`
                        width: 100%;
                      `}
                    />
                  </p>
                  <p className="text-left">
                    <label for="email">Your Email:</label>
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={this.handleChange}
                      className={css`
                        width: 100%;
                      `}
                    />
                  </p>
                  <p className="text-left">
                    <label for="message">Message: </label>
                    <textarea
                      name="message"
                      value={message}
                      onChange={this.handleChange}
                      className={css`
                        width: 100%;
                        height: 200px;
                      `}
                    />
                  </p>
                  <p className="text-left">
                    <Button
                      type={"submit"}
                      className={"btn btn-lg btn-block btn-dark"}
                    >
                      {" "}
                      Send{" "}
                    </Button>
                  </p>
                </form>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className={"col-12 col-lg-12 text-center"}>
                <h1>You are awesome!</h1>
                <p>
                  Thanks for sending me an email. I just recieved a notification
                  and it made me smile =){" "}
                </p>
              </Col>
            </Row>
          )}
        </Container>
      </Layout>
    )
  }
}

export default Contact
